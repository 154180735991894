var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "breakContract" },
    [
      _c(
        "van-tabs",
        {
          attrs: { animated: "", sticky: "", type: "card" },
          on: { change: _vm.acticeTabsChange },
          model: {
            value: _vm.active,
            callback: function($$v) {
              _vm.active = $$v
            },
            expression: "active"
          }
        },
        [
          _c(
            "van-tab",
            { attrs: { title: "卖料", name: "sell" } },
            [
              _c(
                "div",
                { staticClass: "goodsTabs" },
                [
                  _c(
                    "van-tabs",
                    {
                      attrs: {
                        sticky: "",
                        color: "#FF2D2E",
                        "title-active-color": "#FF2D2E"
                      },
                      on: { change: _vm.goodsTabsChangeSell },
                      model: {
                        value: _vm.activeName,
                        callback: function($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName"
                      }
                    },
                    [
                      _c("van-tab", {
                        attrs: { title: "黄金", name: "huangjin" }
                      }),
                      _c("van-tab", { attrs: { title: "铂金", name: "bojin" } })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("sell", { ref: "sell", attrs: { goodsType: _vm.activeName } })
            ],
            1
          ),
          _c(
            "van-tab",
            { attrs: { title: "买料", name: "buy" } },
            [
              _c(
                "div",
                { staticClass: "goodsTabs" },
                [
                  _c(
                    "van-tabs",
                    {
                      attrs: {
                        sticky: "",
                        color: "#FF2D2E",
                        "title-active-color": "#FF2D2E"
                      },
                      on: { change: _vm.goodsTabsChangeBuy },
                      model: {
                        value: _vm.activeName,
                        callback: function($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName"
                      }
                    },
                    [
                      _c("van-tab", {
                        attrs: { title: "黄金", name: "huangjin" }
                      }),
                      _c("van-tab", { attrs: { title: "铂金", name: "bojin" } })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("buy", { ref: "buy", attrs: { goodsType: _vm.activeName } })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tabsLeftIcon", on: { click: _vm.back } },
        [_c("van-icon", { attrs: { name: "arrow-left" } })],
        1
      ),
      _vm.active == "sell"
        ? _c("div", { staticClass: "boxBtn", on: { click: _vm.settlement } }, [
            _c("div", { staticClass: "btn" }, [_vm._v("定价卖料-违约结算")])
          ])
        : _vm._e(),
      _vm.active == "buy"
        ? _c(
            "div",
            { staticClass: "boxBtn", on: { click: _vm.buysettlement } },
            [_c("div", { staticClass: "btn" }, [_vm._v("定价买料-违约结算")])]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }