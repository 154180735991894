<template>
    <div class="breakContract">
        <van-tabs v-model="active" animated sticky type="card" @change="acticeTabsChange">
            <van-tab title="卖料" name="sell">
              <div class="goodsTabs">
                <!-- tab标签页 -->
                <van-tabs v-model="activeName" sticky  color="#FF2D2E" title-active-color="#FF2D2E" @change="goodsTabsChangeSell">
                  <van-tab title="黄金" name="huangjin" >
                  </van-tab>
                  <van-tab title="铂金" name="bojin">
                  </van-tab>
<!--                  <van-tab title="钯金" name="bajin">-->
<!--                  </van-tab>-->
<!--                  <van-tab title="白银" name="baiyin">-->
<!--                  </van-tab>-->
                </van-tabs>
              </div>
              <sell  ref="sell" :goodsType="activeName"/>
                
            </van-tab>
            <van-tab title="买料" name="buy">
              <div class="goodsTabs">
                <!-- tab标签页 -->
                <van-tabs v-model="activeName" sticky  color="#FF2D2E" title-active-color="#FF2D2E" @change="goodsTabsChangeBuy">
                  <van-tab title="黄金" name="huangjin" >
                  </van-tab>
                  <van-tab title="铂金" name="bojin">
                  </van-tab>
<!--                  <van-tab title="钯金" name="bajin">-->
<!--                  </van-tab>-->
<!--                  <van-tab title="白银" name="baiyin">-->
<!--                  </van-tab>-->
                </van-tabs>
              </div>
                <buy ref="buy" :goodsType="activeName"/>
            </van-tab>
         </van-tabs>
         <!-- 左侧图标 -->
         <div class="tabsLeftIcon" @click="back">
            <van-icon name="arrow-left" />
        </div>
    <div v-if="active =='sell'"  @click="settlement" class="boxBtn">
        <div class="btn">定价卖料-违约结算</div>
    </div>    
    <div v-if="active =='buy'"  @click="buysettlement" class="boxBtn">
        <div class="btn">定价买料-违约结算</div>
    </div>
     
    </div>
</template>

<script>
import sell from './sell/index.vue'
import buy from './buy/index.vue'
export default {
    components:{
        sell,
        buy
    },
    data() {
        return {
                active:'sell',
                showbreak:true,
                activeName: 'huangjin'
        }
    },
    mounted() {

            this.$route.query.active?this.active = this.$route.query.active :this.active = 'sell'
            this.$route.query.activeName?this.activeName = this.$route.query.activeName :this.activeName = 'huangjin'
    },
    methods:{
        settlement(){
                this.$refs.sell.breakjieSuan()
        },
        buysettlement(){
                this.$refs.buy.breakjieSuan()

        },
        back(){
            const path = this.$route.query.path
            this.$router.push({
                name: path || 'person'
              })
        },
        goodsTabsChangeSell() {
          this.$refs.sell.goodsTabsChange(this.activeName)
        },
        goodsTabsChangeBuy() {
          this.$refs.buy.goodsTabsChange(this.activeName)
        },
        acticeTabsChange() {
          const than = this;
          setTimeout(() => {
            if(this.active === 'sell') {
              than.$refs.sell.goodsTabsChange(this.activeName)
            } else {
              than.$refs.buy.goodsTabsChange(this.activeName)
            }
          }, 500);
        }
    }
}
</script>

<style lang="scss" scoped>
    ::v-deep .van-dialog{
    width: 90%;
    padding: 0 40px 30px;
    .title{
        color: #242424;

    }
    .van-field{
        font-size: 28px;
    }
  }
  .centerbox_dialog{
  text-align: left;
  // padding: 0 10px;
  font-size: 28px;
}
  .popbtn{
  margin-top: 25px;
  div{
    margin-top: 5px!important;
    border-top: none!important;
    width: 260px;
    text-align: center;
    border-radius: 50px;
  }
  
}
.gocer{
  font-size: 32px;
  padding: 14px 0;
  margin-top: 30px;
  color:#fff;
  text-align: center;
}
.closebtn{
    background-color: #D1D1D1;
}
.confirmbtn{
    background-color:#FF2D2E ;
}
.closebtn{
    background-color: #D1D1D1;
}
.confirmbtn{
    background-color:#FF2D2E ;
}
.breakexplain{
    color: #999;
    font-size: 28px;
    margin: 40px 0;
    p{
        margin-bottom: 10px;
    }
}
.break{
    padding: 4px 0;
    color: #242424;
}
.flex_between{
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
}
.breakContract{
    height: 100%;
    background-color: #f5f5f5;
}
::v-deep{

    .van-tabs__wrap{
        height: 88px;
        padding: 0 220px;
        background-color: #fff;
    }
    .van-tabs__nav{
        height: 50px;
        margin-top: 16px;
        border-radius: 10px ;
    }
    .van-tab{
        height: 100%;
        line-height: 60px;
        font-size: 28px;
        padding: 0;
    }
}

.tabsLeftIcon{
    position: fixed;
    top: 30px;
    left: 10px;
    z-index: 9999;
    .van-icon{
        font-size: 32px;
    }
}
.boxBtn{
    width: 100%;
    height: 84px;
    position: fixed;
    bottom: 0;
    left: 0;
}
.btn{
        width: 100%;
        height: 84px;
        background-color:#FF2D2E ;
        color: #fff;
        text-align: center;
        line-height: 84px;
        font-size: 32px;
}
.goodsTabs {
  margin-top: 30px;
  ::v-deep {
    .van-tabs__wrap {
      height: 58px;
      padding: 0;
      //background-color: #ffffff;
    }
    .van-tabs__nav{
      height: auto;
      margin-top: 0;
      border-radius: 0px;
    }
    .van-tabs__line {
      width: 80px;
      border-width: 5px;
    }
  }
}
</style>